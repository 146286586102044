.progress-border .progress-bar {
    width: 100%;
    height: 3px;
    background-color: #243837!important;
    position: relative;
  }
  
  .progress-border {
    margin: 20px;
    border-radius: 10px;
    border: 1px solid black;
    background-color: #243837;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  .progress {
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
  }
  
  .circle {
    width: 45px;
    height: 45px;
    background-color: #9090a3;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  /*  store overview */
  .count-circle-1 {
    width: 25px;
    height: 25px;
    background-color: #B1FA63!important;
    border-radius: 50%;
    position: absolute;
    top: 0%;
    left: 92%;
    transform: translateY(-50%);
    z-index: 0;
  }
  .count-circle-2 {
    width: 25px;
    height: 25px;
    background-color: #B1FA63!important;
    border-radius: 50%;
    position: absolute;
    top: 0%;
    left: 81%;
    transform: translateY(-50%);
    z-index: 0;
  }
  .count-circle-3 {
    width: 25px;
    height: 25px;
    background-color: #B1FA63!important;
    border-radius: 50%;
    position: absolute;
    top: 0%;
    left: 85%;
    transform: translateY(-50%);
    z-index: 0;
  }
  .count-circle-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #243837!important;
    font-size: 12px;
  }
  .btn-border
  {
 border: 1px solid rgb(136, 135, 135)!important;
  }
  /*  store overview */
  .active {
    background-color: #f4f4f7;
  }
  .active-nav {
    background-color: #e0f3ff;
    border-radius: 10px!important;
  }
 
  .circle-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
    font-size: 12px;
  }
  
  .circle-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
    font-size: 24px;
  }
  
  .d-none {
    display: none;
  }
  .circle-round 
  {
    font-size: 48px!important;
    color:#7c817e;
  }
  .circle-round-green 
  {
    font-size: 48px!important;
    color:#2bc767;
  }
  .heddings
  {
    margin-top: -50px !important;
    position: relative;
    left: -180px;
    color: #a3a3a8;
    /* opacity: 50%; */
  }
  .heddings-last
  {
    margin-top: -20px;
    position: relative;
    left: -160px;
    color: #f4f4f7;
    opacity: 50%;
  
  }
.text-font
{
  font-family: 'Poppins'!important;
  color: #000000!important;
  font-size: 20px;
  font-weight: 500;
}
.conaitent-heading
{
  font-family: 'Poppins'!important;
  color: #000000!important;
  font-weight: 900;

}
.productlistitem
{
  margin-left: 0px !important;
  width: 90% !important;
}
.row-background-color
{
  border-radius: 10px;
  background-color: rgb(240, 249, 255)!important;
}
/* td
{
  font-weight: 700;
  padding: 10px;
  background-color:white!important;
} */
/* .asin-price {
  background-color:white!important;
  padding:10px;
  font-weight: 700;
} */
.dropdown-style{
  color: #03223e;
  
}
.color-changing{
  background-color: #03223e;

}
.radio-button{
  color: #000000;
  size: 50px;
  background-color: #03223e;
  margin: 0px;
  padding-top: 60px;
}
.row-background-color-blue
{
  margin: 15px!important;
  border-radius: 10px;
  /* background-color: #03223e!important; */
  background-color: #134879!important;
}
.progressBar-active-text {
  color: #ffffff!important;
  /* opacity: 99%!important; */
  top: -14px;
}
.ml-2rem
{
  margin-left: 2rem!important;
}
.status-open 
{
  background-color: #ed8077!important;
  color: white!important;
  width: 90px!important;
}
.status-closed 
{
  background-color: #a1af2f!important;
  color: white!important; 
  width: 90px!important;
}
.status-progress 
{
  background-color: #4488c5!important;
  color: white!important;
  width: 90px!important;
}
.status-resolved 
{

  background-color: #5eb5a6!important;
  color: white!important;
  width: 90px!important;
}
@media screen and (min-width: 768px) and (max-width: 1440px) {
  /* Styles for screens with a width between 768px and 1023px */

/* .count-circle-1 {
  width: 25px;
  height: 25px;
  background-color: #2f5c86!important;
  border-radius: 50%;
  position: absolute;
  top: 0%;
  left: 92%;
  transform: translateY(-50%);
  z-index: 1000;
}
.count-circle-2 {
  width: 25px;
  height: 25px;
  background-color: #2f5c86!important;
  border-radius: 50%;
  position: absolute;
  top: 0%;
  left: 81%;
  transform: translateY(-50%);
  z-index: 1000;
}
.count-circle-3 {
  width: 25px;
  height: 25px;
  background-color: #2f5c86!important;
  border-radius: 50%;
  position: absolute;
  top: 0%;
  left: 85%;
  transform: translateY(-50%);
  z-index: 1000;
}
.count-circle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff!important;
  font-size: 12px;
} */
}